import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import SectionTitle from '../../../components/shared/section-title'
import {TeamSectionWrap, TeamContainer} from './team.stc'
import TechnologiesGIF from "../../../data/teams/images/Technologies.gif"


const TeamSection = ({wrapperOne, wrapperTwo, fadeDirections}) => {
    const teamQueryData = useStaticQuery(graphql `
        query TeamDataQuery {
            aboutdataJson(jsonId: {eq: "about-team-section-content"}) {
                title
                subtitle
            }
            allTeamsJson {
                edges {
                    node {
                        id
                        name
                        role
                        image {
                            childImageSharp {
                                fluid(quality: 100) {
                                    ...GatsbyImageSharpFluid
                                    presentationWidth
                                    presentationHeight
                                }
                            }
                        }
                    }
                }
            }
        }      
    `); 
    // const teamSecData = teamQueryData.aboutdataJson;
    // const teamMembers = teamQueryData.allTeamsJson.edges;
    // const admins = teamMembers.filter(member => member.node.role === 'administrator');
    // const employees = teamMembers.filter(member => member.node.role === 'employee'); 
    // const technologies = teamMembers.filter(member => member.node.role === 'technology');
    // const delay = [300];
    return (
        <TeamSectionWrap>
            <TeamContainer>
                {/* <div className="col-1 offset-1">
                    <SectionTitle
                        subtitle={teamSecData.subtitle}
                        title={teamSecData.title}
                        subtitleStyle={{color: 'white'}}
                        titleStyle={{color: 'lightseagreen'}}
                    />
                </div> */}
                {/* <TeamWrapper {...wrapperOne}>
                    <div className="row">
                        <div className="col-4 offset-1">
                            <div className="row">
                                {admins.map((admin, i) => {
                                    delay.push(delay[i] + 200)
                                    return(
                                        <div className="col-1" key={`admin-${admin.node.id}`}>
                                            <Team 
                                                imageSrc={admin.node.image.childImageSharp.fluid}
                                                name={admin.node.name}
                                                designation={admin.node.designation}
                                                role={admin.node.role}
                                                animDelay={`${delay[i]}ms`}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </TeamWrapper>  */}
                {/* <TeamWrapper {...wrapperTwo}>
                    <div className="row">
                        <div className="col-4 offset-1">
                            <div className="row">
                                {employees.map((employee, i) => {
                                    delay.push(delay[i] + 200)
                                    return(
                                        <div className="col-1" key={`employee-${employee.node.id}`}>
                                            <Team 
                                                imageSrc={employee.node.image.childImageSharp.fluid}
                                                name={employee.node.name}
                                                designation={employee.node.designation}
                                                role={employee.node.role}
                                                animDelay={`${delay[i]}ms`}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </TeamWrapper>  */}
                <div className="col-2 offset-1">
                    <SectionTitle
                        // subtitle="CHECK OUT"
                        title="Technologies we use"
                        subtitleStyle={{color: 'white'}}
                        titleStyle={{color: 'lightseagreen'}}
                    />
                </div>
                {/* <TeamWrapper {...wrapperOne}>
                    <div className="row">
                        <div className="col-4 offset-1">
                            <div className="row">
                                {technologies.map((technology, i) => (
                                    <div className="col-1 technologies" key={`technology-${technology.node.id}`}>
                                        <Team
                                        imageSrc={technology.node.image.childImageSharp.fluid}
                                        name={technology.node.name}
                                        designation={technology.node.designation}
                                        role={technology.node.role}
                                        animDelay={`${delay[i]}ms`}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </TeamWrapper> */}
                <div className="col-4 offset-1">
                    <img style={{marginLeft: "auto"}} src={ TechnologiesGIF } alt="technologies"/>
                </div>

            </TeamContainer>
        </TeamSectionWrap>
    )
} 

TeamSection.propTypes = {
    wrapperOne: PropTypes.object,
    wrapperTwo: PropTypes.object,
    fadeDirections: PropTypes.array
}

TeamSection.defaultProps = {
    wrapperOne: {
        pt: '75px'
    },
    wrapperTwo: {
        pt: '20px'
    },
    fadeDirections: ["left", "right", "top", "bottom"]
}

export default TeamSection
