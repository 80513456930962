import React from "react"
import SEO from '../components/seo';
import Layout from '../containers/layout/layout'
import Banner from '../containers/company/banner'
import About from '../containers/company/about'
import Team from '../containers/company/team'
import JoinOurTeam from '../containers/company/join-our-team'
import Testimonial from '../containers/company/testimonial'
import Contact from '../containers/footer-contact-form';

export default () => (
    <Layout headerLayout={2}>
        <SEO title="Atlasopen - We Build on Proven Technologies" description="We use a wide array and breath of technologies from the Operating System layer all the way up the Stack. The combination of knowledge and expertise across these domains sets Atlasopen apart from the competition." />
        {/* <Banner/> */}
        {/* <About/> */}
        <Team/>
        <JoinOurTeam/>
        <Testimonial/>
        <Contact/>

    </Layout>
)
